<template>
    <div class="p-2 card">
        <b-row>
            <b-col
                md="12"
                lg="6">
                <h1>Gerenciar categorias</h1>
                <p>
                    Para realizar uma busca, selecione o(s) filtros necessário(s) e clique no botão buscar:
                </p>
            </b-col>

            <b-col
                md="12"
                lg="6"
                class="text-end"
            >

                <b-button
                    v-if="permiteCadastrar===true"
                    @click="atualizarRegistros = false; mostrarModal = true;"
                    type="button"
                    variant="outline-primary"
                    class="float-right"
                >
                    <BIconPlusCircle />
                    &nbsp;
                    {{ this.textPermiteCadastrar }}
                </b-button>
                <b-button
                    v-else
                    disabled="disabled"
                    type="button"
                    variant="outline-primary"
                    class="float-right"
                >
                    <BIconPlusCircle />
                    &nbsp;
                    {{ this.textPermiteCadastrar }}
                </b-button>
            </b-col>
        </b-row>

        <section class="filtros mt-2">
            <b-form>
                <b-row>
                    <b-col xl="12" md="12">
                        <b-form-group label="Termo de pesquisa" label-for="pesquisa">
                            <b-form-input
                                id="pesquisa"
                                v-model="busca.pesquisa"
                                autocomplete="off"
                                @keydown.prevent.enter="buscarDadosCategoria"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>

                <div class="w-100 d-flex justify-content-center my-2">
                    <b-button
                        class="mr-2"
                        type="reset"
                        variant="outline-secondary"
                        @click="limparFiltros"
                    >
                        Limpar
                    </b-button>

                    <b-button
                        :disabled="desabilitarBotao"
                        @click.prevent="buscarDadosCategoria"
                        ref="filtrosAjuda"
                        type="button"
                        variant="custom"
                    >
                        Buscar
                    </b-button>
                </div>
            </b-form>

            <b-modal
                id="modal-categoria"
                ref="modal-categoria"
                :title="tituloModal"
                size="lg"
                centered
                v-model="mostrarModal"
                @close="esconderModal"
                :hide-footer="true"
                :no-close-on-esc="true"
                :no-close-on-backdrop="true"
            >
                <FormCategorias :form="form"
                                :modules="modulosSelect"
                                :update-action="atualizarRegistros"
                                @success="sucessoInserirAtualizar"
                                @cancel="esconderModal"
                />
            </b-modal>

            <b-modal
                id="modal-detalhes-categoria"
                ref="modall-detalhes-categoria"
                title="Detalhes da categoria de ajuda"
                size="lg"
                centered
                v-model="mostrarModalDetalhes"
                @close="esconderModalDetalhes"
                :hide-footer="true"
                :no-close-on-esc="true"
                :no-close-on-backdrop="true"
            >
                <DetalhesCategoria :form="form"
                               :modules="modulosSelect"
                               @cancel="esconderModalDetalhes"
                />
            </b-modal>
        </section>

        <div v-if="carregandoTabela" class="w-100 mt-5 d-flex flex-column align-items-center justify-content-center">
            <b-spinner variant="custom" class="mb-1" label="Loading..."></b-spinner>
            <span>carregando...</span>
        </div>

        <b-alert variant="primary" :show="tabela.semDados" class="mt-5">
            <div class="alert-body d-flex justify-content-center">
                <span class="text-primary">
                    <strong class="text-primary">Nenhum registro encontrado.</strong>
                    Realize novamente uma busca utilizando diferentes critérios.
                </span>
            </div>
        </b-alert>

        <b-alert variant="primary" :show="tabela.erroTabela" class="mt-5">
            <div class="alert-body d-flex justify-content-center">
                <span class="text-primary">
                    <strong class="text-primary">Sistema de busca indisponível no momento.</strong>
                </span>
            </div>
        </b-alert>

        <section v-if="mostrarTabela">
            <b-row class="my-2">
                <b-col sm="2">
                    <div class="d-flex flex-row align-items-center">
                        <label class="mr-50">Visualizar</label>
                        <b-form-select
                            v-model="dadosPaginacao.quantidadeLinhasPadrao"
                            class="w-50"
                            size="sm"
                            id="amounlinesVisualizer"
                            label="option"
                            @input="atualizaQuantidadeVisualizar($event)"
                            :options="tabela.quantidadeLinhasVisualizar"
                        />
                    </div>
                </b-col>
                <b-col sm="12" class="my-2">
                    <b-table
                        id="listCompaniesTable"
                        class="font-small-3"
                        responsive
                        @context-changed="ordenarTabela"
                        :busy.sync="tabela.tabelaOcupada"
                        :no-local-sorting="true"
                        :fields="tabela.fields"
                        :items="tabela.items"
                    >
                        <template #cell(icone)="row">
                            <feather-icon v-if="row.value"
                                          :icon="row.value"
                                          size="18"
                                          class="mr-1 icone-categoria"
                            />
                            <feather-icon v-else
                                          icon="CircleIcon"
                                          size="18"
                                          class="mr-1 icone-categoria"
                            />
                        </template>
                        <template #cell(modulos)="row">
                            <span v-for="(modulos, index) in row.value" :key="index">
                                {{ modulos.descricao }}{{ index !== (row.value.length - 1) ? ', ' : '' }}
                                <br />
                            </span>
                        </template>
                        <template #cell(acoes)="row">
                            <b-button v-if="permiteEditar===true" @click="retornaCategoriaId(row.item)" variant="outline-primary" class="btn-icon">
                                <feather-icon icon="EditIcon" />
                            </b-button>
                            <b-button v-else @click="retornaCategoriaIdDetalhes(row.item)" variant="outline-primary" class="btn-icon">
                                <feather-icon icon="EyeIcon" />
                            </b-button>
                        </template>
                    </b-table>
                </b-col>
                <b-col sm="12">
                    <CustomPagination :paginacao="dadosPaginacao" @page-cliked="atualizarPaginaAtual"/>
                </b-col>
            </b-row>
        </section>
    </div>
</template>

<script>
import {
    BAlert,
    BButton,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BIconPlusCircle,
    BRow,
    BSpinner,
    BTable,
    BTableSimple,
    BTbody,
    BTd,
    BTr,
} from 'bootstrap-vue';
import vSelect from "vue-select";
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import Cleave from 'vue-cleave-component'
import CustomPagination from '@/views/components/custom/pagination/CustomPagination.vue';
import DetalhesCategoria from "@/views/pages/ajuda-suporte/DetalhesCategoria";
import FormCategorias from "@/views/pages/ajuda-suporte/FormCategoria.vue";
import helpers from "@/helpers";
import {$regraAcao, $regraEntidade} from "@/custom-enum/regras-enum";

export default {
    components: {
        BForm,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BIconPlusCircle,
        BFormSelect,
        BButton,
        Cleave,
        BTable,
        CustomPagination,
        vSelect,
        BTableSimple,
        BTbody,
        BTr,
        BTd,
        BAlert,
        BSpinner,
        ValidationProvider,
        ValidationObserver,
        FormCategorias,
        DetalhesCategoria
    },

    mounted() {
        this.dadosSelect();
        this.handleVerificaSePodeCadastrar();
        this.handleVerificaSePodeEditar();
    },

    data() {
        return {
            desabilitarBotao: false,
            mostrarModalDetalhes: false,
            permiteEditar: false,
            permiteCadastrar: false,
            textPermiteCadastrar: 'Você não tem permissão para incluir',
            busca: {
                pesquisa: '',
            },

            form: {
                id_categoria: '',
                titulo: '',
                icone: '',
                descricao: '',
                modulos: [],
            },

            atualizarRegistros: false,

            ajudas: [],

            modulosSelect: [],
            placeholderModulos: 'Aguarde, buscando registros...',

            mostrarModal: false,
            tituloModal: 'Incluir nova categoria',

            mostrarTabela: false,
            carregandoTabela: false,

            dadosPaginacao: {
                paginaAtual: 1,
                totalLinhas: 1,
                daLinha: 1,
                ateALinha: 1,
                quantidadeLinhasPadrao: 10,
            },

            tabela: {
                semDados: false,
                erroTabela: false,
                tabelaOcupada: false,
                quantidadeLinhasVisualizar: [10, 25, 50, 100],
                ordem: '',
                campoOrdenado: '',
                fields: [
                    {key: "icone", label: "Ícone", sortable: true, class: 'mw-150 text-center col-lg-1 font-small-3'},
                    {key: "titulo", label: "Título", sortable: true, class: 'mw-200 font-small-3'},
                    {key: "descricao", label: "Descricao", sortable: true, class: 'mw-400 font-small-3'},
                    {key: "modulos", label: "Módulos", sortable: false, class: 'mw-100 font-small-3'},
                    {key: "acoes", label: "Ações", class: 'mw-150 text-center col-lg-1 font-small-3'}
                ],
                items: []
            }
        }
    },

    methods: {
        handleVerificaSePodeCadastrar(){

            const regra = {
                action: $regraAcao.inserir,
                subject: $regraEntidade.portal.ajudaESuporte.categoria,
            };

            this.$store.state.sessions.userData.ability.filter((ability) => {
                if (
                    regra.action == ability.action &&
                    regra.subject == ability.subject
                ) {
                    this.permiteCadastrar = true;
                    this.textPermiteCadastrar = 'Incluir Nova Categoria';
                }
            });
        },

        handleVerificaSePodeEditar(){
            const regra = {
                action: $regraAcao.atualizar,
                subject: $regraEntidade.portal.ajudaESuporte.categoria,
            };

            this.$store.state.sessions.userData.ability.filter((ability) => {
                if (
                    regra.action == ability.action &&
                    regra.subject == ability.subject
                ) {
                    this.permiteEditar = true;
                }
            });
        },

        async dadosSelect() {
            await this.retornaModulos();
        },

        buscarDadosCategoria(firstTime = null) {
            if(firstTime && !this.mostrarTabela) {
                this.carregandoTabela = true;
                this.desabilitarBotao = true;
            }

            this.tabela.erroTabela = false;

            this.tabela.semDados = false;
            this.tabela.tabelaOcupada = true;

            this.$http.get(this.$api.AjudaCategoria, {
                params: this.lidarComParametrosBuscaCategoria()
            }).then((response) => {

                this.desabilitarBotao = false;

                if(response.status === 200) {
                    if(response.data.data.length > 0) {
                        this.mostrarTabela = true;
                        this.tabela.items = response.data.data;
                        this.tabela.tabelaOcupada = false;
                        this.carregandoTabela = false;
                        this.IniciarPaginacao(response.data);
                        return;
                    }
                    this.carregandoTabela = false;
                    this.tabela.semDados = true;
                    this.mostrarTabela = false;
                }
            }).catch(() => {
                this.desabilitarBotao = false;
                this.carregandoTabela = false;
                this.tabela.erroTabela = true;
            });
        },

        sucessoInserirAtualizar() {
            this.esconderModal();

            this.buscarDadosCategoria();
        },

        async retornaCategoriaId(categoria) {
            this.mostrarTabela = false;
            this.carregandoTabela = true;

            this.form.id_categoria = categoria.id_categoria;

            await this.$http.get(`${this.$api.AjudaCategoria}/${this.form.id_categoria}`)
                .then((response) => {
                    const categoriaResponse = response.data;

                    this.form.titulo = categoriaResponse.titulo;
                    this.form.descricao = categoriaResponse.descricao;
                    this.form.icone = (categoriaResponse.anexo!=null ? categoriaResponse.anexo.arquivo : '');
                    this.form.modulos = ( categoriaResponse.modulos!=null ? helpers.retornaAtributoArray(categoriaResponse.modulos, 'id_modulo'):null);

                    this.tituloModal = 'Editar categoria';
                    this.atualizarRegistros = true;
                    this.mostrarModal = true;
                });

            this.mostrarTabela = true;
            this.carregandoTabela = false;
        },
        async retornaCategoriaIdDetalhes(categoria) {
            this.mostrarTabela = false;
            this.carregandoTabela = true;

            this.form.id_categoria = categoria.id_categoria;

            await this.$http.get(`${this.$api.AjudaCategoria}/${this.form.id_categoria}`)
                .then((response) => {
                    const categoriaResponse = response.data;

                    this.form.titulo = categoriaResponse.titulo;
                    this.form.descricao = categoriaResponse.descricao;
                    this.form.icone = (categoriaResponse.anexo!=null ? categoriaResponse.anexo.arquivo : '');
                    this.form.modulos = categoriaResponse.modulos ?? null;

                    this.atualizarRegistros = true;
                    this.mostrarModalDetalhes = true;
                });

            this.mostrarTabela = true;
            this.carregandoTabela = false;
        },

        ordenarTabela(contextoTabela) {
            this.tabela.campoOrdenado = contextoTabela.sortBy;
            this.tabela.ordem = contextoTabela.sortDesc ? "desc" : "asc";

            this.buscarDadosCategoria();
        },

        lidarComParametrosBuscaCategoria() {
            return {
                "pesquisa": this.busca.pesquisa,
                "colunaNome": this.tabela.campoOrdenado,
                "colunaOrdem": this.tabela.ordem,
                "perPage": this.dadosPaginacao.quantidadeLinhasPadrao,
                "page": this.dadosPaginacao.paginaAtual
            }
        },

        limparFiltros() {
            this.busca.pesquisa = '';

            this.mostrarTabela = false;

            this.limparPaginacao();
        },

        limparCampos() {
            this.form = {
                titulo: '',
                descricao: '',
                icone: '',
                modulos: [],
            };

            this.tituloModal = 'Incluir nova categoria';
        },

        limparPaginacao() {
            this.dadosPaginacao = {
                paginaAtual: 1,
                totalLinhas: 1,
                daLinha: 1,
                ateALinha: 1,
                quantidadeLinhasPadrao: 10,
            };
        },

        IniciarPaginacao(dadosPaginacao) {
            this.dadosPaginacao.daLinha = dadosPaginacao.from;
            this.dadosPaginacao.ateALinha = dadosPaginacao.to;
            this.dadosPaginacao.totalLinhas = dadosPaginacao.total;
            this.dadosPaginacao.paginaAtual = dadosPaginacao.current_page;
            this.dadosPaginacao.quantidadeLinhasPadrao = dadosPaginacao.per_page;
        },

        atualizarPaginaAtual(page) {
            this.dadosPaginacao.paginaAtual = page;
            this.buscarDadosCategoria()
        },

        atualizaQuantidadeVisualizar(event) {
            if(!event) {
                this.dadosPaginacao.quantidadeLinhasPadrao = 10;
            }

            this.dadosPaginacao.paginaAtual = 1;
            this.buscarDadosCategoria();
        },

        esconderModal() {
            this.limparCampos();
            this.mostrarModal = !this.mostrarModal;
        },

        esconderModalDetalhes() {
            this.limparCampos();
            this.mostrarModalDetalhes = !this.mostrarModalDetalhes;
        },

        retornaModulos() {
            this.$http.get(this.$api.ModulosBusca).then((res) => {
                this.modulosSelect = res.data;
                this.placeholderModulos = 'Selecione um ou mais módulos';
            });
        },
    },
}
</script>
