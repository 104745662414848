<template>
    <div>
        <div v-if="loading" class="w-100 mt-5 mb-5 d-flex flex-column align-items-center justify-content-center">
            <b-spinner variant="custom" class="mb-1" label="Loading..."></b-spinner>
            <span>Processando...</span>
        </div>
        <b-form v-if="!loading">
            <b-row>
                <b-col cols="12" sm="12" lg="12" class="mb-2">
                    <b-form-group label="Título *" label-for="titulo">
                        <b-form-input
                            id="titulo"
                            v-model="getForm.titulo"
                            autocomplete="off"
                            placeholder="Título da categoria"
                            readonly="readonly"
                        />
                        <small class="text-danger">{{ errors.titulo }}</small>
                    </b-form-group>
                </b-col>

                <b-col cols="12" sm="12" lg="12" class="mb-2">
                    <b-form-group label="Descrição *" label-for="descricao">
                        <b-form-textarea
                            id="descricao"
                            rows="6"
                            v-model="getForm.descricao"
                            autocomplete="off"
                            placeholder="Digite a descrição da categoria"
                            readonly="readonly"
                        />
                        <small class="text-danger">{{ errors.descricao }}</small>
                    </b-form-group>
                </b-col>

                <b-col cols="12" sm="6" lg="6" class="mb-2">
                    <label class="m-0">Ícone *</label>
                    <div class="border rounded bg-light" style="padding: 5px" >
                    <feather-icon
                        :icon="getForm.icone"
                        size="16"
                        class="align-middle mr-50"
                    />
                    <span> {{ getForm.icone }}</span>
                    </div>
                </b-col>

                <b-col cols="12" sm="6" lg="6" class="mb-2">
                    <label class="m-0">Módulos *</label>
                    <div class="border rounded bg-light" style="padding: 5px">
                        <span v-for="(modulo,index) in getForm.modulos" class="badge badge-primary mr-1">{{ modulo.descricao }}</span>
                    </div>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" sm="12" class="text-center">
                    <button
                        @click="handleCancel"
                        type="button"
                        class="btn btn-outline-cancel"
                    >
                        Fechar
                    </button>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>

<script>
import FieldModulos from '@/views/components/custom/modulos/ModulosSelect.vue';
import vSelect from "vue-select";
import {validation} from "@core/mixins/validation/validation";
import {messages} from "@core/mixins/validation/messages";

import {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BSpinner,
    BFormTextarea
} from 'bootstrap-vue';

export default {
    components: {
        FieldModulos,
        BForm,
        BRow,
        BCol,
        BButton,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BSpinner,
        BFormTextarea,
        vSelect,
    },
    props: [
        'form',
        'modules',
        'updateAction',
    ],

    mixins: [validation, messages],

    mounted() {
        this.setCurrentData();
    },

    data() {
        return {
            icons: [
                'ActivityIcon',
                'AirplayIcon',
                'AlertCircleIcon',
                'AlertOctagonIcon',
                'AlertTriangleIcon',
                'AlignCenterIcon',
                'AlignJustifyIcon',
                'AlignLeftIcon',
                'AlignRightIcon',
                'AnchorIcon',
                'ApertureIcon',
                'ArchiveIcon',
                'ArrowDownCircleIcon',
                'ArrowDownLeftIcon',
                'ArrowDownRightIcon',
                'ArrowDownIcon',
                'ArrowLeftCircleIcon',
                'ArrowLeftIcon',
                'ArrowRightCircleIcon',
                'ArrowRightIcon',
                'ArrowUpCircleIcon',
                'ArrowUpLeftIcon',
                'ArrowUpRightIcon',
                'ArrowUpIcon',
                'AtSignIcon',
                'AwardIcon',
                'BarChart2Icon',
                'BarChartIcon',
                'BatteryChargingIcon',
                'BatteryIcon',
                'BellOffIcon',
                'BellIcon',
                'BluetoothIcon',
                'BoldIcon',
                'BookOpenIcon',
                'BookIcon',
                'BookmarkIcon',
                'BoxIcon',
                'BriefcaseIcon',
                'CalendarIcon',
                'CameraOffIcon',
                'CameraIcon',
                'CastIcon',
                'CheckCircleIcon',
                'CheckSquareIcon',
                'CheckIcon',
                'ChevronDownIcon',
                'ChevronLeftIcon',
                'ChevronRightIcon',
                'ChevronUpIcon',
                'ChevronsDownIcon',
                'ChevronsLeftIcon',
                'ChevronsRightIcon',
                'ChevronsUpIcon',
                'ChromeIcon',
                'CircleIcon',
                'ClipboardIcon',
                'ClockIcon',
                'CloudDrizzleIcon',
                'CloudLightningIcon',
                'CloudOffIcon',
                'CloudRainIcon',
                'CloudSnowIcon',
                'CloudIcon',
                'CodeIcon',
                'CodepenIcon',
                'CodesandboxIcon',
                'CoffeeIcon',
                'ColumnsIcon',
                'CommandIcon',
                'CompassIcon',
                'CopyIcon',
                'CornerDownLeftIcon',
                'CornerDownRightIcon',
                'CornerLeftDownIcon',
                'CornerLeftUpIcon',
                'CornerRightDownIcon',
                'CornerRightUpIcon',
                'CornerUpLeftIcon',
                'CornerUpRightIcon',
                'CpuIcon',
                'CreditCardIcon',
                'CropIcon',
                'CrosshairIcon',
                'DatabaseIcon',
                'DeleteIcon',
                'DiscIcon',
                'DivideCircleIcon',
                'DivideSquareIcon',
                'DivideIcon',
                'DollarSignIcon',
                'DownloadCloudIcon',
                'DownloadIcon',
                'DribbbleIcon',
                'DropletIcon',
                'Edit2Icon',
                'Edit3Icon',
                'EditIcon',
                'ExternalLinkIcon',
                'EyeOffIcon',
                'EyeIcon',
                'FacebookIcon',
                'FastForwardIcon',
                'FeatherIcon',
                'FigmaIcon',
                'FileMinusIcon',
                'FilePlusIcon',
                'FileTextIcon',
                'FileIcon',
                'FilmIcon',
                'FilterIcon',
                'FlagIcon',
                'FolderMinusIcon',
                'FolderPlusIcon',
                'FolderIcon',
                'FramerIcon',
                'FrownIcon',
                'GiftIcon',
                'GitBranchIcon',
                'GitCommitIcon',
                'GitMergeIcon',
                'GitPullRequestIcon',
                'GithubIcon',
                'GitlabIcon',
                'GlobeIcon',
                'GridIcon',
                'HardDriveIcon',
                'HashIcon',
                'HeadphonesIcon',
                'HeartIcon',
                'HelpCircleIcon',
                'HexagonIcon',
                'HomeIcon',
                'ImageIcon',
                'InboxIcon',
                'InfoIcon',
                'InstagramIcon',
                'ItalicIcon',
                'KeyIcon',
                'LayersIcon',
                'LayoutIcon',
                'LifeBuoyIcon',
                'Link2Icon',
                'LinkIcon',
                'LinkedinIcon',
                'ListIcon',
                'LoaderIcon',
                'LockIcon',
                'LogInIcon',
                'LogOutIcon',
                'MailIcon',
                'MapPinIcon',
                'MapIcon',
                'Maximize2Icon',
                'MaximizeIcon',
                'MehIcon',
                'MenuIcon',
                'MessageCircleIcon',
                'MessageSquareIcon',
                'MicOffIcon',
                'MicIcon',
                'Minimize2Icon',
                'MinimizeIcon',
                'MinusCircleIcon',
                'MinusSquareIcon',
                'MinusIcon',
                'MonitorIcon',
                'MoonIcon',
                'MoreHorizontalIcon',
                'MoreVerticalIcon',
                'MousePointerIcon',
                'MoveIcon',
                'MusicIcon',
                'Navigation2Icon',
                'NavigationIcon',
                'OctagonIcon',
                'PackageIcon',
                'PaperclipIcon',
                'PauseCircleIcon',
                'PauseIcon',
                'PenToolIcon',
                'PercentIcon',
                'PhoneCallIcon',
                'PhoneForwardedIcon',
                'PhoneIncomingIcon',
                'PhoneMissedIcon',
                'PhoneOffIcon',
                'PhoneOutgoingIcon',
                'PhoneIcon',
                'PieChartIcon',
                'PlayCircleIcon',
                'PlayIcon',
                'PlusCircleIcon',
                'PlusSquareIcon',
                'PlusIcon',
                'PocketIcon',
                'PowerIcon',
                'PrinterIcon',
                'RadioIcon',
                'RefreshCcwIcon',
                'RefreshCwIcon',
                'RepeatIcon',
                'RewindIcon',
                'RotateCcwIcon',
                'RotateCwIcon',
                'RssIcon',
                'SaveIcon',
                'ScissorsIcon',
                'SearchIcon',
                'SendIcon',
                'ServerIcon',
                'SettingsIcon',
                'Share2Icon',
                'ShareIcon',
                'ShieldOffIcon',
                'ShieldIcon',
                'ShoppingBagIcon',
                'ShoppingCartIcon',
                'ShuffleIcon',
                'SidebarIcon',
                'SkipBackIcon',
                'SkipForwardIcon',
                'SlackIcon',
                'SlashIcon',
                'SlidersIcon',
                'SmartphoneIcon',
                'SmileIcon',
                'SpeakerIcon',
                'SquareIcon',
                'StarIcon',
                'StopCircleIcon',
                'SunIcon',
                'SunriseIcon',
                'SunsetIcon',
                'TabletIcon',
                'TagIcon',
                'TargetIcon',
                'TerminalIcon',
                'ThermometerIcon',
                'ThumbsDownIcon',
                'ThumbsUpIcon',
                'ToggleLeftIcon',
                'ToggleRightIcon',
                'ToolIcon',
                'Trash2Icon',
                'TrashIcon',
                'TrelloIcon',
                'TrendingDownIcon',
                'TrendingUpIcon',
                'TriangleIcon',
                'TruckIcon',
                'TvIcon',
                'TwitchIcon',
                'TwitterIcon',
                'TypeIcon',
                'UmbrellaIcon',
                'UnderlineIcon',
                'UnlockIcon',
                'UploadCloudIcon',
                'UploadIcon',
                'UserCheckIcon',
                'UserMinusIcon',
                'UserPlusIcon',
                'UserXIcon',
                'UserIcon',
                'UsersIcon',
                'VideoOffIcon',
                'VideoIcon',
                'VoicemailIcon',
                'Volume1Icon',
                'Volume2Icon',
                'VolumeXIcon',
                'VolumeIcon',
                'WatchIcon',
                'WifiOffIcon',
                'WifiIcon',
                'WindIcon',
                'XCircleIcon',
                'XOctagonIcon',
                'XSquareIcon',
                'XIcon',
                'YoutubeIcon',
                'ZapOffIcon',
                'ZapIcon',
                'ZoomInIcon',
                'ZoomOutIcon',
            ],
            errors: {},
            currentModules: [],
            loading: false,
        }
    },

    methods: {
        handleFormData(formData) {
            return {
                titulo: formData.titulo,
                descricao: formData.descricao,
                icone: formData.icone,
                modulos: formData.modulos
            };
        },

        handleClear() {

            this.errors = {};
        },

        handleCancel() {
            this.handleClear();
            this.$emit('cancel');
        },

        core() {
            this.updateAction ? this.handleUpdate() : this.handleInsert();
        },

        setCurrentData() {
            this.currentModules = this.getForm.modulos;
        },
    },

    computed: {
        getForm() {
            return this.form;
        }
    }
}
</script>

<style scoped>
    .btn-insert {
        background: linear-gradient(45deg, #7030A0 0%, #A369CE 100%);
        color: #fff;
        transition: .3s background-color;
    }

    .btn-insert:hover {
        background: linear-gradient(45deg, #60248d 0%, #9950d0 100%);
        color: #fff;
    }

    .btn-outline-cancel {
        background-color: transparent;
        border: solid 0.094rem #82868B;
        color: #82868B;
        transition: .3s;
    }

    .btn-outline-cancel:hover {
        text-decoration: underline;
    }

    @media(max-width: 576px) {
        .btn-insert, .btn-outline-cancel  {
            width: 100%;
            margin-bottom: 15px;
        }
    }
</style>
