<template>
    <div>
        <div v-if="loading" class="w-100 mt-5 mb-5 d-flex flex-column align-items-center justify-content-center">
            <b-spinner variant="custom" class="mb-1" label="Loading..."></b-spinner>
            <span>Processando...</span>
        </div>
        <b-form v-if="!loading">
            <b-row>
                <b-col cols="12" sm="12" lg="12" class="mb-2">
                    <b-form-group label="Título *" label-for="titulo">
                        <b-form-input
                            id="titulo"
                            v-model="getForm.titulo"
                            autocomplete="off"
                            placeholder="Título da categoria"
                        />
                        <small class="text-danger">{{ errors.titulo }}</small>
                    </b-form-group>
                </b-col>

                <b-col cols="12" sm="12" lg="12" class="mb-2">
                    <b-form-group label="Descrição *" label-for="descricao">
                        <b-form-textarea
                            id="descricao"
                            rows="6"
                            v-model="getForm.descricao"
                            autocomplete="off"
                            placeholder="Digite a descrição da categoria"
                        />
                        <small class="text-danger">{{ errors.descricao }}</small>
                    </b-form-group>
                </b-col>

                <b-col cols="12" sm="6" lg="6" class="mb-2">
                    <b-form-group label="Ícone *" label-for="icone" class="m-0">
                        <v-select
                            id="icone"
                            v-model="getForm.icone"
                            :options="icons"
                            label="icons"
                        >
                            <template #option="icon">
                                <feather-icon
                                    :icon="icon['icons']"
                                    size="16"
                                    class="align-middle mr-50"
                                />
                                <span> {{ icon['icons'] }}</span>
                            </template>
                        </v-select>
                    </b-form-group>

                    <small class="text-danger">{{ errors.icone }}</small>
                </b-col>

                <b-col cols="12" sm="6" lg="6" class="mb-2">
                    <b-form-group label="Módulos *" label-for="modulos" class="m-0">
                        <v-select
                            id="modulos"
                            v-model="getForm.modulos"
                            :reduce="(option => option.id_modulo)"
                            :options="modules"
                            variant="custom"
                            item-text="descricao"
                            item-value="id_modulo"
                            label="descricao"
                            placeholder="Selecione 1 ou mais"
                            multiple
                            multiselect
                        />
                    </b-form-group>

                    <small class="text-danger">{{ errors.modulos }}</small>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" sm="6">
                    <button @click="handleCancel" type="button" class="btn btn-outline-cancel float-right">
                        Cancelar
                    </button>
                </b-col>

                <b-col cols="12" sm="6">
                    <button @click="core" type="button" class="btn btn-insert float-left">
                        Salvar
                    </button>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>

<script>
import FieldModulos from '@/views/components/custom/modulos/ModulosSelect.vue';
import vSelect from "vue-select";
import { validation } from "@core/mixins/validation/validation";
import { messages } from "@core/mixins/validation/messages";
import { modalGenericModel } from '@/libs/sweetalerts'

import {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BSpinner,
    BFormTextarea
} from 'bootstrap-vue';

export default {
    components: {
        FieldModulos,
        BForm,
        BRow,
        BCol,
        BButton,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BSpinner,
        BFormTextarea,
        vSelect,
    },
    props: [
        'form',
        'modules',
        'updateAction',
    ],

    mixins: [validation, messages],

    mounted() {
        this.setCurrentData();
    },

    data() {
        return {
            icons: [
                'ActivityIcon',
                'AirplayIcon',
                'AlertCircleIcon',
                'AlertOctagonIcon',
                'AlertTriangleIcon',
                'AlignCenterIcon',
                'AlignJustifyIcon',
                'AlignLeftIcon',
                'AlignRightIcon',
                'AnchorIcon',
                'ApertureIcon',
                'ArchiveIcon',
                'ArrowDownCircleIcon',
                'ArrowDownLeftIcon',
                'ArrowDownRightIcon',
                'ArrowDownIcon',
                'ArrowLeftCircleIcon',
                'ArrowLeftIcon',
                'ArrowRightCircleIcon',
                'ArrowRightIcon',
                'ArrowUpCircleIcon',
                'ArrowUpLeftIcon',
                'ArrowUpRightIcon',
                'ArrowUpIcon',
                'AtSignIcon',
                'AwardIcon',
                'BarChart2Icon',
                'BarChartIcon',
                'BatteryChargingIcon',
                'BatteryIcon',
                'BellOffIcon',
                'BellIcon',
                'BluetoothIcon',
                'BoldIcon',
                'BookOpenIcon',
                'BookIcon',
                'BookmarkIcon',
                'BoxIcon',
                'BriefcaseIcon',
                'CalendarIcon',
                'CameraOffIcon',
                'CameraIcon',
                'CastIcon',
                'CheckCircleIcon',
                'CheckSquareIcon',
                'CheckIcon',
                'ChevronDownIcon',
                'ChevronLeftIcon',
                'ChevronRightIcon',
                'ChevronUpIcon',
                'ChevronsDownIcon',
                'ChevronsLeftIcon',
                'ChevronsRightIcon',
                'ChevronsUpIcon',
                'ChromeIcon',
                'CircleIcon',
                'ClipboardIcon',
                'ClockIcon',
                'CloudDrizzleIcon',
                'CloudLightningIcon',
                'CloudOffIcon',
                'CloudRainIcon',
                'CloudSnowIcon',
                'CloudIcon',
                'CodeIcon',
                'CodepenIcon',
                'CodesandboxIcon',
                'CoffeeIcon',
                'ColumnsIcon',
                'CommandIcon',
                'CompassIcon',
                'CopyIcon',
                'CornerDownLeftIcon',
                'CornerDownRightIcon',
                'CornerLeftDownIcon',
                'CornerLeftUpIcon',
                'CornerRightDownIcon',
                'CornerRightUpIcon',
                'CornerUpLeftIcon',
                'CornerUpRightIcon',
                'CpuIcon',
                'CreditCardIcon',
                'CropIcon',
                'CrosshairIcon',
                'DatabaseIcon',
                'DeleteIcon',
                'DiscIcon',
                'DivideCircleIcon',
                'DivideSquareIcon',
                'DivideIcon',
                'DollarSignIcon',
                'DownloadCloudIcon',
                'DownloadIcon',
                'DribbbleIcon',
                'DropletIcon',
                'Edit2Icon',
                'Edit3Icon',
                'EditIcon',
                'ExternalLinkIcon',
                'EyeOffIcon',
                'EyeIcon',
                'FacebookIcon',
                'FastForwardIcon',
                'FeatherIcon',
                'FigmaIcon',
                'FileMinusIcon',
                'FilePlusIcon',
                'FileTextIcon',
                'FileIcon',
                'FilmIcon',
                'FilterIcon',
                'FlagIcon',
                'FolderMinusIcon',
                'FolderPlusIcon',
                'FolderIcon',
                'FramerIcon',
                'FrownIcon',
                'GiftIcon',
                'GitBranchIcon',
                'GitCommitIcon',
                'GitMergeIcon',
                'GitPullRequestIcon',
                'GithubIcon',
                'GitlabIcon',
                'GlobeIcon',
                'GridIcon',
                'HardDriveIcon',
                'HashIcon',
                'HeadphonesIcon',
                'HeartIcon',
                'HelpCircleIcon',
                'HexagonIcon',
                'HomeIcon',
                'ImageIcon',
                'InboxIcon',
                'InfoIcon',
                'InstagramIcon',
                'ItalicIcon',
                'KeyIcon',
                'LayersIcon',
                'LayoutIcon',
                'LifeBuoyIcon',
                'Link2Icon',
                'LinkIcon',
                'LinkedinIcon',
                'ListIcon',
                'LoaderIcon',
                'LockIcon',
                'LogInIcon',
                'LogOutIcon',
                'MailIcon',
                'MapPinIcon',
                'MapIcon',
                'Maximize2Icon',
                'MaximizeIcon',
                'MehIcon',
                'MenuIcon',
                'MessageCircleIcon',
                'MessageSquareIcon',
                'MicOffIcon',
                'MicIcon',
                'Minimize2Icon',
                'MinimizeIcon',
                'MinusCircleIcon',
                'MinusSquareIcon',
                'MinusIcon',
                'MonitorIcon',
                'MoonIcon',
                'MoreHorizontalIcon',
                'MoreVerticalIcon',
                'MousePointerIcon',
                'MoveIcon',
                'MusicIcon',
                'Navigation2Icon',
                'NavigationIcon',
                'OctagonIcon',
                'PackageIcon',
                'PaperclipIcon',
                'PauseCircleIcon',
                'PauseIcon',
                'PenToolIcon',
                'PercentIcon',
                'PhoneCallIcon',
                'PhoneForwardedIcon',
                'PhoneIncomingIcon',
                'PhoneMissedIcon',
                'PhoneOffIcon',
                'PhoneOutgoingIcon',
                'PhoneIcon',
                'PieChartIcon',
                'PlayCircleIcon',
                'PlayIcon',
                'PlusCircleIcon',
                'PlusSquareIcon',
                'PlusIcon',
                'PocketIcon',
                'PowerIcon',
                'PrinterIcon',
                'RadioIcon',
                'RefreshCcwIcon',
                'RefreshCwIcon',
                'RepeatIcon',
                'RewindIcon',
                'RotateCcwIcon',
                'RotateCwIcon',
                'RssIcon',
                'SaveIcon',
                'ScissorsIcon',
                'SearchIcon',
                'SendIcon',
                'ServerIcon',
                'SettingsIcon',
                'Share2Icon',
                'ShareIcon',
                'ShieldOffIcon',
                'ShieldIcon',
                'ShoppingBagIcon',
                'ShoppingCartIcon',
                'ShuffleIcon',
                'SidebarIcon',
                'SkipBackIcon',
                'SkipForwardIcon',
                'SlackIcon',
                'SlashIcon',
                'SlidersIcon',
                'SmartphoneIcon',
                'SmileIcon',
                'SpeakerIcon',
                'SquareIcon',
                'StarIcon',
                'StopCircleIcon',
                'SunIcon',
                'SunriseIcon',
                'SunsetIcon',
                'TabletIcon',
                'TagIcon',
                'TargetIcon',
                'TerminalIcon',
                'ThermometerIcon',
                'ThumbsDownIcon',
                'ThumbsUpIcon',
                'ToggleLeftIcon',
                'ToggleRightIcon',
                'ToolIcon',
                'Trash2Icon',
                'TrashIcon',
                'TrelloIcon',
                'TrendingDownIcon',
                'TrendingUpIcon',
                'TriangleIcon',
                'TruckIcon',
                'TvIcon',
                'TwitchIcon',
                'TwitterIcon',
                'TypeIcon',
                'UmbrellaIcon',
                'UnderlineIcon',
                'UnlockIcon',
                'UploadCloudIcon',
                'UploadIcon',
                'UserCheckIcon',
                'UserMinusIcon',
                'UserPlusIcon',
                'UserXIcon',
                'UserIcon',
                'UsersIcon',
                'VideoOffIcon',
                'VideoIcon',
                'VoicemailIcon',
                'Volume1Icon',
                'Volume2Icon',
                'VolumeXIcon',
                'VolumeIcon',
                'WatchIcon',
                'WifiOffIcon',
                'WifiIcon',
                'WindIcon',
                'XCircleIcon',
                'XOctagonIcon',
                'XSquareIcon',
                'XIcon',
                'YoutubeIcon',
                'ZapOffIcon',
                'ZapIcon',
                'ZoomInIcon',
                'ZoomOutIcon',
            ],
            errors: {},
            currentModules: [],
            loading: false,
        }
    },

    methods: {

        async handleInsert() {
            if(this.handleValidation()) {
                this.loading = true;

                const formData = this.handleFormData(this.getForm);

                await this.$http.post(this.$api.AjudaCategoria, formData)
                    .then((response) => {
                        if(response.status === 201) {
                            this.handleClear();
                            this.handleModalSuccess();
                        }
                    })
                    .catch((error) => {
                        this.handleApiError(error.response);
                    })

                this.loading = false;
            }
        },

        async handleUpdate() {
            if(this.handleValidation()) {
                this.loading = true;

                const formData = this.handleFormData(this.getForm);

                await this.$http.put(`${this.$api.AjudaCategoria}/${this.getForm.id_categoria}`, formData)
                    .then((response) => {
                        if(response.status === 204) {
                            this.handleClear();
                            this.handleModalSuccess();
                        }
                    })
                    .catch((error) => {
                        this.handleApiError(error.response);
                    })

                this.loading = false;
            }
        },

        handleValidation() {
            this.strValidate({
                input: this.getForm.titulo,
                message: this.requiredFieldMsg,
                field: 'titulo'
            });

            this.strValidate({
                input: this.getForm.descricao,
                message: this.requiredFieldMsg,
                field: 'descricao'
            });

            this.strValidate({
                input: this.getForm.icone,
                message: this.requiredFieldMsg,
                field: 'icone'
            });

            this.arrayEmptyValidate('modulos', this.getForm.modulos);

            if(! this.formValidationRun()) {
                this.errors = {};
                this.errors = this.formValidationErrors;
                return false;
            }

            return true;
        },

        handleApiError(response) {
            const status = response.status;
            const error = response.data.error;

            this.errors = {};

            if(status === 400) {
                switch (error.field) {
                    case 'titulo':
                        this.errors.titulo = error.message;
                    break;
                    case 'descricao':
                        this.errors.descricao = error.message;
                    break;
                    case 'icone':
                        this.errors.icone = error.message;
                    break;
                    case 'modulos':
                        this.errors.modulos = error.message;
                    break;
                }
            } else {
              modalGenericModel(
                'Não foi possível realizar sua requisição, entre em contato com o suporte.',
                '',
                require('@/assets/custom-icons/cora-icons/warning.png'),
                'Ok'
              )
            }
        },

        handleFormData(formData) {
            return {
                titulo: formData.titulo,
                descricao: formData.descricao,
                icone: formData.icone,
                modulos: formData.modulos
            };
        },

        handleModalSuccess() {
          modalGenericModel(
            'Sucesso!',
            this.MensagemConfirmacao,
            require('@/assets/custom-icons/cora-icons/success.png'),
            'Ok'
          ).then((result) => {
            if (result.isConfirmed) {
              this.$emit('success');
            }
          });
        },

        handleClear() {

            this.errors = {};
        },

        handleCancel() {
            this.handleClear();
            this.$emit('cancel');
        },

        core() {
            this.updateAction ? this.handleUpdate() : this.handleInsert();
        },

        setCurrentData() {
            this.currentModules = this.getForm.modulos;
        },
    },

    computed: {
        getForm() {
            return this.form;
        }
    }
}
</script>

<style scoped>
    .btn-insert {
        background: linear-gradient(45deg, #7030A0 0%, #A369CE 100%);
        color: #fff;
        transition: .3s background-color;
    }

    .btn-insert:hover {
        background: linear-gradient(45deg, #60248d 0%, #9950d0 100%);
        color: #fff;
    }

    .btn-outline-cancel {
        background-color: transparent;
        border: solid 0.094rem #82868B;
        color: #82868B;
        transition: .3s;
    }

    .btn-outline-cancel:hover {
        text-decoration: underline;
    }

    @media(max-width: 576px) {
        .btn-insert, .btn-outline-cancel  {
            width: 100%;
            margin-bottom: 15px;
        }
    }
</style>
