<template>
  <validation-observer ref="simpleRules">
    <validation-provider
      #default="{ errors }"
      :rules="required ? 'required' : ''"
    >
      <b-form-group
        :state="errors.length > 0 ? false : null"
        :label="getNomeLabel"
        label-for="Select-Modulos"
        class="m-0"
      >
        <v-select
          id="Select-Modulos"
          v-model="ModulosSelecionados"
          @input="SelecionaModulos"
          multiple
          variant="custom"
          item-text="descricao"
          item-value="id_modulo"
          label="descricao"
          :options="Modulos"
          multiselect
          :placeholder="placeholderProp"
          :disabled="Disabled"
        />
        <small v-if="errors.length > 0" class="text-danger"
          >Campo Obrigatório</small
        >
      </b-form-group>
    </validation-provider>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { BCol, BFormGroup } from "bootstrap-vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BCol,
    BFormGroup,
  },
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    values: {
      type: Array,
      default: [],
    },
    disabledProp: {
      type: Boolean,
      default: false,
    },
    empresaTipo: {
      type: Number,
      default: 1,
      required: false,
    },
    grupo: {
      type: Object,
      default() {
        return {};
      },
      required: false,
    },
    placeholderProp: {
      type: String,
      default: 'Serviços Contratados',
    },
    nomeLabel: {
      type: String,
      default: 'Serviços Contratados',
    },
  },

  data() {
    return {
      ModulosSelecionados: [],
      Modulos: [],
      Disabled: false,
      rota: this.$api.ModulosBusca,
      filtradoPorGrupo: false,
    };
  },
  methods: {
    SelecionaModulos() {
      this.$emit("GetModulos", this.ModulosSelecionados);
    },
    buscaModulos() {
      this.$http.get(this.rota).then((res) => {
        this.Modulos = !this.filtradoPorGrupo
          ? res.data
          : res.data.data.modulos;
      });
    },
  },

  beforeMount() {
    if (this.empresaTipo != 1) {
      let rota = this.$helpers.preparaRotaComParametros(
        this.$api.GrupoDetalhe,
        "{grupo}",
        this.grupo.id_empresa_grupo
      );

      this.rota = this.$helpers.preparaRotaComParametros(
        rota,
        "{relacionamento}",
        "modulos"
      );
      this.filtradoPorGrupo = true;
    }
  },
  mounted() {
    this.buscaModulos();
  },

  computed: {
    getNomeLabel() {
      if (this.required) {
        let nomeLabel = this.nomeLabel;

        return `${nomeLabel} *`;
      }

      return this.nomeLabel;
    }
  },

  watch: {
    values(value) {

      this.ModulosSelecionados = value;
    },
    disabledProp(value) {
      this.Disabled = value;
    },
  },
};
</script>
